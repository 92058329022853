.login {
    background-color: #006DFE;
    min-height:100vh;
    padding-top:100px;
}
.login-wrapper {
    background-color: var(--primary);
    border-radius: 0;
    padding: 0;
    width: 1000px;
    max-width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
}
.login-form {
    background-color: #fff;
    padding: 50px 20px 20px 20px;
    height: 600px;
    h3 {
        font-weight: 600;
        color: #0054C6;
        font-size: 1.5vw;
        margin: 30px 0 10px 0;
        text-align: right;
        span {
            color: red;
            font-size: 2.5vw;
            font-weight: 600;
        }
    }
    h4 {
        font-weight: 400;
        color: #0054C6;
        font-size: 1vw;
        margin-bottom: 30px;
        text-align: right;
    }
}
.login-info {
    display: flex;
    align-items: end;
    justify-content: center;
    height: 100%;
    background-image: url('https://png.pngtree.com/thumb_back/fw800/back_our/20190622/ourmid/pngtree-orange-e-commerce-background-download-image_220954.jpg');
}
.login-ranks {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: end;
    gap:10px;
    width: 400px;
    max-width: 100%;
    .columns {
        height: 200px;
        position: relative;
        background: rgb(255,216,178);
        background: -moz-linear-gradient(190deg, rgba(255,216,178,1) 0%, rgba(255,228,200,1) 39%, rgba(255,239,224,1) 100%);
        background: -webkit-linear-gradient(190deg, rgba(255,216,178,1) 0%, rgba(255,228,200,1) 39%, rgba(255,239,224,1) 100%);
        background: linear-gradient(190deg, rgba(255,216,178,1) 0%, rgba(255,228,200,1) 39%, rgba(255,239,224,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd8b2",endColorstr="#ffefe0",GradientType=1);
        border-radius: 5px 5px 0 0;
        display: flex; flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        align-content: flex-start;
        .txt-top {
            position: relative;
            width: 100px;
            text-align: center;
            margin-top: 10px;
            border-radius: 10px;
            color:#fff; font-weight: bold;
            background: rgb(255,126,0);
            background: -moz-linear-gradient(90deg, rgba(255,126,0,1) 0%, rgba(255,158,56,1) 39%, rgba(255,239,224,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,126,0,1) 0%, rgba(255,158,56,1) 39%, rgba(255,239,224,1) 100%);
            background: linear-gradient(90deg, rgba(255,126,0,1) 0%, rgba(255,158,56,1) 39%, rgba(255,239,224,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7e00",endColorstr="#ffefe0",GradientType=1);
            span {
                font-size: 3em;
                position: absolute;
                top:-30px;
                right: 10px;
                color:rgba(255,126,0,1);
                text-shadow:0 0 2px #000;
            }
        }
        .txt-name {
            width:100%;
            text-align: center;
            margin-top: 10px;
            font-size: 1.5em; font-weight: bold;
        }
    }
    .avatar {
        width: 100%; height:125px;
        position:absolute;
        top:-150px;
        border-radius: 50%;
        background: rgb(255,139,0);
        background: -moz-linear-gradient(190deg, rgba(255,139,0,1) 0%, rgba(255,207,0,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
        background: -webkit-linear-gradient(190deg, rgba(255,139,0,1) 0%, rgba(255,207,0,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
        background: linear-gradient(190deg, rgba(255,139,0,1) 0%, rgba(255,207,0,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8b00",endColorstr="#fefefe",GradientType=1);
        display: flex; align-items: center; justify-content: center;
        .avatar-inner {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            border-radius: 50%;
            overflow: hidden;
        }
        img {
            width: 100%;
        }
        .medal {
            position: absolute;
            top:-10px;
            left:50%;
            margin-left: -20px;
            flex-shrink: 0;
            z-index: 1;
            width: 30px;
            text-align: center;
            &:after, &:before {
                content: "";
                display: block;
                position: absolute;
                border-style: solid;
                border-width: 3px 6px;
                width: 0;
                height: 11px;
                top: 22px;
                z-index: -1;
            }
            &:before {
                border-color: #FC402D #FC402D transparent #FC402D;
                left: 4px;
                transform: rotate(20deg) translateZ(-32px);
            }
            &:after {
                left: 15px;
                border-color: #f31903 #f31903 transparent #f31903;
                transform: rotate(-20deg) translateZ(-48px);
            }
            small {
                display: block;
                font-size: 18px;
                font-weight: 500;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                color: #fff;
                line-height: 28px;
                vertical-align: middle;
                position: relative;
                border-width: .2em;
                border-style: solid;
                z-index: 1;
                box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, .8);
                border-color: #fadd40;
                text-shadow: 0 0 4px #9d6c04;
                background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
            }
        }
    }
    .rank-2 {
        height:150px;
        .avatar {
            background: rgb(244,92,5);
            background: -moz-linear-gradient(190deg, rgba(244,92,5,1) 0%, rgba(206,122,46,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
            background: -webkit-linear-gradient(190deg, rgba(244,92,5,1) 0%, rgba(206,122,46,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
            background: linear-gradient(190deg, rgba(244,92,5,1) 0%, rgba(206,122,46,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f45c05",endColorstr="#fefefe",GradientType=1);
        }
        .txt-top {
            span {
                font-size: 2.5em;
                top:-25px;
                color:rgb(211,211,211);
            }
        }
        .medal {
            small {
                box-shadow: inset 0 0 0 #ffb988, 2px 2px 0 rgba(0, 0, 0, 0.8);
                border-color: #fdc298;
                text-shadow: 0 0 4px #ffa76a;
                background: linear-gradient(to bottom right, #f57820 50%, #d75800 50%);
            }
        }
    }
    .rank-3 {
        height:100px;
        .avatar {
            background: rgb(91,91,91);
            background: -moz-linear-gradient(190deg, rgba(91,91,91,1) 0%, rgba(159,159,159,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
            background: -webkit-linear-gradient(190deg, rgba(91,91,91,1) 0%, rgba(159,159,159,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
            background: linear-gradient(190deg, rgba(91,91,91,1) 0%, rgba(159,159,159,1) 56%, rgba(254,254,254,1) 97%, rgba(254,254,254,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b5b5b",endColorstr="#fefefe",GradientType=1);
        }
        .txt-top {
            span {
                font-size: 2em;
                top:-15px;
                color:rgba(254,254,254,1);
            }
        }
        .medal {
            small {
                box-shadow: inset 0 0 0 #a7b2b8, 1px 1px 0 rgba(0,0,0,.08);
                border-color: #edeff1;
                text-shadow: 1px 1px 0 #98a6ad;
                background: linear-gradient(to bottom right,#d1d7da 50%,#c3cbcf 50%);
            }
        }
    }
}

@media(max-width:600px) {
    .login {
        padding-top: 0;
        background-color: var(--primary);
    }
    .login-wrapper {
        max-width:100%;
        display: block;
    }
    .login-form {
        background-color: #fff;
        padding: 20px 20px 20px 20px;
        height: 400px;
        margin: 10px;
        border-radius: 10px;
    }
    .login-ranks {
        padding: 120px 10px 0 10px;
        .columns {
            height: 100px;
        }
        .avatar {
            height:110px;
            top:-100px;
        }
        .rank-2 {
            height:80px;
        }
        .rank-3 {
            height:50px;
        }
    }
}