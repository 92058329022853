.menu {
    display: flex;
    list-style: none;
}
.menu-items {
    font-size:13px;
}
.menu-item {
    position: relative;
    overflow: hidden;
    line-height: 1rem;
    .count {
        position: absolute; top:0px; right:0px;
        display: flex; align-items: center; justify-content: center;
        width:20px; height:20px;
        border-radius:50%;
        background-color: #feeded;
        color: rgb(206, 21, 21);
        font-size:0.7rem; font-weight: 500;
    }
    &:hover {
        overflow: inherit;
        >a {
            background-color: rgba(167,167,167,0.3);
        }
        .dropdown {
            opacity: 1;
        }
    }
}
.item-icon {
    margin-right: 10px;
}
.dropdown {
    min-width:200px;
    list-style: none;
    position: absolute; top:48px;
    box-shadow: 0 0 10px rgb(167 167 167 / 50%);
    background-color: #fff;
    border-radius:10px;
    opacity: 0;
    visibility: visible;
    transition: all 0.5s;
    z-index: 1;
    &:before {
        content: "";
        display: block;
        top: -10px;
        height: 20px;
        width: 100%;
        position: absolute;
    }
    a {
        width:100%;
        justify-content: space-between;
    }
    .menu-item {
        &:first-child {
            border-radius:10px 10px 0 0!important;
            a:hover {
                border-radius:10px 10px 0 0!important;
            }
        }
        &:last-child {
            border-radius:0 0 10px 10px!important;
            a:hover {
                border-radius:0 0 10px 10px!important;
            }
        }
    }

    .dropdown {
        left: 100%;
        top: 0;
    }
}