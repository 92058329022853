.card-wrapper {
    position:relative;
    color:#fff;
    .card-bg {
        img {
            width: 638px;
            max-width:100%;
        }
    }
    .card-avatar {
        position:absolute;
        top:16%; left:30%;
        width: 41%;
        padding-top: 41%;
        border-radius: 50%;
        overflow:hidden;
        img {
            position: absolute; top:0; left: 0;
            height: 100%; width: 100%; object-fit: cover;
        }
    }
    .name {
        position:absolute;
        top:48%; width: 100%;
        font-size:2rem;
        font-weight:bold;
        text-align:center;
        text-transform:uppercase;
    }
    .role {
        position:absolute;
        top:54%; width: 100%;
        font-size:1.2rem;
        text-align:center;
        text-transform:capitalize;
    }
    .info {
        position:absolute; left:36%;
        font-size:1.6rem;
        font-weight:bold;
        text-transform:uppercase;
        line-height: 0;
    }
    .code {
        top:63.5%;
    }
    .email {
        top:68.5%; font-size:1rem;
    }
    .phone {
        top:73%;
    }
}