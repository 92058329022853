.status {
    --color:255,255,255;
    --bg-opacity:0.1;
    --border-color:rgba(var(--color), 0.8);
    --bg-color:rgba(var(--color), var(--bg-opacity));
    --txt-color:rgb(var(--color));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-family: var(--font-family);
    color: var(--txt-color);
    border: 0 solid var(--border-color);
    background-color: var(--bg-color);
    &.border {
        border-width: 1px;
    }
    &.small {
        padding: 0 8px;
        line-height: 25px;
    }
}
.pointer {
    cursor: pointer;
}
.white {
    --color:0,0,0;
    --border-color:rgba(0,0,0, 0.8);
    --bg-color:rgba(255,255,255,1);
}
.black {
    --color:255,255,255;
    --border-color:rgba(0,0,0, 0.8);
    --bg-color:rgba(0,0,0,0.5);
}
.red-mint {
    --color:169, 79, 97;
    //color: rgb(169, 79, 97)
}
.red {
    --color:254,44,85;
}
.red-bold {
    --color:137, 8, 34;
}
.blue-mint {
    --color:28,153,173;
}
.blue {
    --color:59, 138, 255;
}
.blue-bold {
    --color:27, 77, 151;
}
.yellow-mint {
    --color:165, 167, 100;
}
.yellow {
    --color:252,203,102;
}
.yellow-bold {
    --color:174, 166, 5;
}
.green-mint {
    --color:32, 195, 149;
}
.green {
    --color:32, 195, 84;
}
.bold {
    font-weight: bold;
}

.background {
    --txt-color:rgb(255,255,255);
    --bg-opacity:1;
}